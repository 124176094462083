@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
}

.hero {
  font-size: 155px;
}

.nav-items {
  font-size: 155px;
}

.heading {
  font-size: 155px;
}

.small-heading {
  font-size: 100px;
}

@media only screen and (max-height: 800px) and (min-width: 1024px) {
  #hero-image {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media only screen and (max-height: 1200px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 300px !important;
  }
}
@media only screen and (min-height: 1201px) and (max-height: 1250px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 300px !important;
  }
}
@media only screen and (min-height: 1251px) and (max-height: 1300px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 275px !important;
  }
}
@media only screen and (min-height: 1301px) and (max-height: 1350px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 250px !important;
  }
}
@media only screen and (min-height: 1351px) and (max-height: 1400px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 200px !important;
  }
}
@media only screen and (min-height: 1401px) and (max-height: 1450px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 175px !important;
  }
}
@media only screen and (min-height: 1451px) and (max-height: 1500px) and (min-width: 1024px) {
  .wwa-padding {
    padding-top: 150px !important;
  }
}
